<template>
  <div class="search-block">
    <div class="row">
      <label class="input-block col-xl-3 col-md-3 col-sm-12">
          <input type="text"  v-model="form.order_number"><span/>
          <span class="input-block__name" :class="form.order_number.length ?'active' : null" >Order Number</span>
      </label>
      <label class="input-block col-xl-3 col-md-3 col-sm-12">
        <input type="text"  v-model="form.ndc"><span/>
        <span class="input-block__name" :class="form.ndc.length ?'active' : null" >Search NDC</span>
      </label>
      <label class="input-block col-xl-3 col-md-3 col-sm-12">
        <input type="text"  v-model="form.product_name"><span/>
        <span class="input-block__name" :class="form.product_name.length ?'active' : null" >Search by Name</span>
      </label>
      <div class="col-md-3 col-sm-12">
        <div class="d-flex justify-center">
          <button class="btn grey" @click="searchOrders()">Search</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      form:{
        order_number:'',
        ndc:'',
        product_name:'',
        pharmacy_wholesaler:'',
      }
    }
  },
  methods:{
    searchOrders(){
      console.log(' really i find you!!')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.search-block{
  width: 100%;
  padding: 40px 100px;
  border-radius: 27px;
  background-color: $white-grey;
}
</style>
